<script>
import Agreement from '@/components/shared/agreements/Agreement'
import rg4js from 'raygun4js'
import EnrollmentService from '@/services/EnrollmentService'
import reloadPageMixin from '@/mixins/reload-page-mixin.js'
import BrandingService from '@/services/BrandingService'

export default {
	name: 'TermsConditions',
	components: { Agreement },
	mixins: [reloadPageMixin],
	data: () => ({
		layout: 'public-layout',
		location: 'TERMS',
		allAgreed: null,
		brandingConfig: null,
		showView: false,
		submitClicked: false
	}),
	computed: {
		enrollmentStatus: function () {
			return this.enrollmentService ? this.enrollmentService.getStatus() : null
		},
		enableSignature: function () {
			return this.brandingConfig && this.brandingConfig.ui && this.brandingConfig.ui.enableSignature
		},
		submitDisabled: function () {
			return !this.allAgreed || this.submitClicked || this.isEnrollmentComplete
		},
		addSpinner: function () {
			return this.allAgreed && this.submitClicked
		},
		isEnrollmentComplete: function () {
			return this.enrollmentService && this.enrollmentService.isComplete()
		},
		continueDisabled: function () {
			return !this.allAgreed || this.isEnrollmentComplete
		}
	},
	async activated () {
		this.submitClicked = false
		this.enrollmentService = await EnrollmentService.get()
		if (!this.brandingConfig) this.brandingConfig = BrandingService.get().getConfig()
		if (!this.enrollmentStatus) {
			this.showView = false
			await this.$router.push({ name: 'error' })
		}
	},
	methods: {
		goToConfirmation: function () {
			this.$router.push({ name: 'confirmation', hash: '#confirmation-section' })
		},
		signEnrollment: function () {
			this.$router.push({ name: 'electronicSignature', hash: '#signature-section' })
		},
		completeEnrollment: async function () {
			this.submitClicked = true
			try {
				await this.enrollmentService.completeEnrollment()
				await this.$router.replace({ name: 'thankYou' })
			} catch (error) {
				await rg4js('send', {
					error: 'Error completing enrollment',
					tags: ['Enrollment not completable']
				})
				await this.$router.push({ name: 'error' })
			}
		},
		handleAllAgreed: function (allAgreed) {
			this.allAgreed = allAgreed
			this.showView = true
		}
	}
}
</script>
<template>
	<component :is="layout" class="terms-conditions">
		<div class="terms-conditions__body"
			id="terms-section"
			v-show="showView">
			<h2 class="terms-conditions__title">{{ $t('terms.title') }}</h2>
			<agreement :location="location"
						@no-agreements="completeEnrollment"
						@all-agreed="handleAllAgreed" />
			<base-button v-if="enableSignature"
				:disabled="continueDisabled"
				modifiers="accent large set-height margin-y"
				@click="signEnrollment()">{{ $t('signature.button') }}</base-button>
			<base-button v-else :disabled="submitDisabled"
						modifiers="accent large set-height margin-y"
						@click="completeEnrollment()">
				<font-awesome-icon :icon="['fas', 'spinner']" class="terms-conditions__spinner" spin v-if="addSpinner" />
				<div v-else>{{ $t('terms.submit') }}</div></base-button>
			<div class="terms-conditions__link" @click="goToConfirmation()">
				{{ $t('terms.cancel') }}
			</div>
		</div>
	</component>
</template>

<style lang="scss" scoped>
.terms-conditions {
	&__body {
		align-items: center;
		background-color: var(--surface-colour);
		border: 1px solid var(--border-variant-light);
		border-radius: var(--border-radius);
		color: var(--on-surface);
		display: flex;
		flex-direction: column;
		padding: 2rem 1rem;
	}
	&__title {
		font-size: var(--font-size-subheading);
		font-weight: var(--font-weight-subheading);
		letter-spacing: var(--letter-spacing-expanded);
	}
	&__link {
		color:  var(--link-colour);
		cursor: pointer;
		filter: brightness(80%);
		font-size: 1.1rem;
		margin-top: 1rem;
	}
	&__spinner {
		position: relative;
		font-size: 1.5rem;
		z-index: 10;
		opacity: 1;
		transition: .2s ease;
	}
}
</style>
